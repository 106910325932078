<template>
  <div class="home">

    <!--first section-->
    <section class="first-section">
      <p>{{ Object.keys(data).length === 0 ? '' : data.content[0].title.toUpperCase() }}</p>
      <p>{{ Object.keys(data).length === 0 ? '' : data.content[0].subtitle.toUpperCase() }}</p>

      <div class="banner-img">
        <img :src=data.content[1].image.url
             alt="main-banner">
        <div class="banner-text">
          <p>{{ data.content[1].titleTag }}</p>
          <p>{{ data.content[1].title.toUpperCase() }}</p>

          <v-btn
              outlined color="#ffffff"
              @click="goPage('edition', data.content[1].title)">
            LEAN MORE
          </v-btn>
        </div>
      </div>
    </section>

    <!--second section-->
    <section class="second-section">
      <p>Featured Drops</p>
      <div class="drops-cards">
        <card-main
            v-for="(drop, i) in data.content[2].editions" :key="i"

            :title="drop.title"
            :statusLabel="drop.statusLabel"
            :contributors="drop.contributors.edges[0].node.profile.name"
            :avatarAsset="drop.contributors.edges[0].node.profile.avatarAsset.url"
            :coverImg="drop.coverImage.url"
            type="ep"


            @click="goPage('edition', drop.title)"
        />
      </div>
    </section>

    <!--third section-->
    <section class="third-section">
      <p>How it works</p>
      <p>Artists and fans co-own music together.</p>
      <div class="works-cards">
        <v-card v-for="(work, i) in data.content[3].features" :key="i"
                class="works-card"
                max-width="350"
        >
          <img
              :src=work.image.url alt="how-it-works-img"/>
          <div class="works-explain">
            <p>{{ work.title }}</p>
            <p>{{ work.description }}</p>
          </div>
        </v-card>
      </div>
    </section>

    <!--fourth section-->
    <section class="fourth-section">
      <div class="why-title">
        <p>_why enterNFT</p>
        <p>We're building tools to connect artists and fans all <br> around the world like never before.</p>
      </div>
      <div class="why-banner">
        <img :src=data.content[5].banner.url alt="why-img"/>
      </div>
    </section>

    <!--fifth section-->
    <section class="fifth-section">
      <p>_ARTIST</p>
      <p>Are you an artist?</p>
      <div class="artist-apply">
        <p>
          Want to partner with us on this project? We'd love to hear from you. Please get in touch with us to get
          started.
        </p>
        <button @click="goPage('artist-submission')">
          APPLY FOR DROP
        </button>
      </div>
    </section>

    <!--sixth section-->
    <section class="sixth-section">
      <div class="faq-title">
        <p>_FAQ</p>
        <p>Have questions? We got you.</p>
      </div>
      <v-expansion-panels
          class="faq-list"
          accordion>
        <v-expansion-panel
            v-for="(question,i) in data.content[9].items"
            :key="i"
        >
          <v-expansion-panel-header disable-icon-rotate>
            {{ question.title }}
            <template v-slot:actions>
              <v-icon>mdi-check-circle-outline</v-icon>
            </template>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ question.content[0].text }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <div class="visit-buttons">
          <button class="visit-button">VISIT OUR HELP CENTER</button>
        </div>
      </v-expansion-panels>
    </section>


  </div>
</template>

<script>
import CardMain from "@/components/Card/CardMain";

export default {
  name: 'Home',
  components: {
    CardMain,
  },
  data() {
    return {
      data: {},
    }
  },
  async mounted() {
    await this.homeData()
  },
  methods: {
    async homeData() {
      await this.$http.get('/home/data'
      ).then(res => {
        this.data = res.data.page
        console.log(this.data)
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name, edition) {
      this.$router.push({
        name: name,
        params: {
          edition: edition
        }
      })
    },
  }
}
</script>
